import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("batch/upload/task/list", { params });
}

export function Detail(params) {
  // console.log(parmas);
  return request.get("batch/upload/task/detail", { params });
}

export function GetOption(params) {
  // console.log(parmas);
  return request.get("batch/upload/task/operation/list", { params });
}

export function Operation(params) {
  // console.log(parmas);
  return request.post("batch/upload/task/operation/perform", params);
}

export function Add(params) {
  return request.post("batch/upload/task/upload", params);
}

export function Update(params) {
  return request.put("batch/upload/task/data/product", params);
}

export function Delete(data) {
  return request.delete("batch/upload/task/data/product", { data });
}
