<template>
  <div>
    <a-modal v-model:visible="visible" title="任务详情" @ok="handleOk" okText="新建" cancelText="关闭" :afterClose="closeModal" width="95vw" :okButtonProps="{ style: { display: 'none' }}" :centered="true" :destroyOnClose="true">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div class="detailModal">
          <div v-if="option?.length>0" class="w100">
            <label>选择操作：</label>
            <a-popconfirm v-for="item in option" :title="item.description" @confirm="handleChange(item.operation)" okText="确认" cancelText="取消">
              <a-button type="primary" style="margin-right:15px">{{item.operation}}</a-button>
            </a-popconfirm>
          </div>
          <div><label>状态：</label><span>{{info?.status}}</span></div>
          <div><label>类型：</label><span>{{info?.category}}</span></div>
          <div><label>创建人：</label><span>{{info?.creatorName}}</span></div>
          <div><label>创建日期：</label><span>{{info?.createdDate}}</span></div>
          <div v-for="item in info?.infos"><label>{{item.displayName}}：</label><span>{{item.displayValue}}</span></div>
          <!-- <div><label>产品类型：</label><span>{{info?.infos.find(item=>item.displayName=='产品类型')?.displayValue}}</span></div> -->
        </div>
      </a-form>
      <a-table class="ant-table-striped" :columns="columns" :data-source="data" :rowClassName="rowClassName" bordered rowKey="id" :pagination="{ pageSize ,total,showTotal: (total) => `共 ${total} 条`,showSizeChanger:true,pageSizeOptions: ['10', '15', '20', '30', '50']}" :scroll="{ y: 400 }" @change="changePage">
        <template #operation="{ record }">
          <div class="flex_sa">
            <div class="editable-row-operations flex_sa" v-if="!record.isAlias" style="width: 100px;">
              <span v-if="editableData[record.id]">
                <a @click="save(record.id)">保存</a>
              </span>
              <span v-if="editableData[record.id]">
                <a @click="cancel(record.id)">取消</a>
              </span>
              <span v-else>
                <a @click="edit(record.id)">编辑</a>
              </span>
            </div>
            <div class="editable-row-operations" v-if="!record.isAlias">
              <span>
                <a-popconfirm v-if="data.length" title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </div>
          </div>
        </template>

        <template v-for="col in ['standardName']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <!-- <a-input v-model:value="editableData[record.id][col]" /> -->
            <medicineSelector v-if="editableData[record.id]" v-model:value="editableData[record.id].standardName" placeholder="药名" allowClear style="width:200px;text-align: center;" />
            <template v-else>{{ text }}</template>
          </div>
        </template>
      </a-table>
      <span>注：没有关联标准名的数据不参与操作，可以手动删除不需要的数据</span>
    </a-modal>
  </div>
</template>

<script>
// import { Detail } from "/src/api/prescription.js";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import { Detail, GetOption, Operation, Update, Delete } from "/src/api/task.js";
import medicineSelector from '/src/components/selector/medicineSelector.vue';
export default {
  components: {
    medicineSelector
  },
  data() {
    return {
      visible: this.modelValue,
      visible2: false,
      option: [],
      columns: [
        {
          title: "序号",
          key: "num",
          dataIndex: "num",
          width: "5%",
          customRender: ({ index }) => (this.current - 1) * this.pageSize + index + 1,
          slots: {
            customRender: "num",
          }
        },
        {
          title: "名称",
          key: "name",
          dataIndex: "name",
          width: "15%",
          slots: {
            customRender: "name",
          }
        },
        {
          title: "价格",
          key: "price",
          dataIndex: "price",
          width: "15%",
          slots: {
            customRender: "price",
          },
          sorter: (a, b) => a.price - b.price,
        },
        {
          title: "标准名",
          key: "standardName",
          dataIndex: "standardName",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "standardName",
          },
          sorter: (a, b) => {
            if (!a.standardName) return 1;
            if (!b.standardName) return -1;
            return a.standardName.length - b.standardName.length;
          }
        },
        {
          title: "原价",
          key: "oldPrice",
          dataIndex: "oldPrice",
          // ellipsis: true,
          width: "10%",
          slots: {
            customRender: "oldPrice"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          width: "20%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      current: 1,
      pageSize: 8,
      info: [],
      editableData: reactive({}),
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {

  },
  activated() {
  },
  //获取父元素传值
  props: {
    record: {
      type: Object,
      default: {},
    },
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
  },
  methods: {
    async detail() {
      let res = await Detail({ id: this.record.id });
      this.info = res.data;
      this.data = res.data?.dataList;
      console.log(this.info);
      this.columns = this.columns.filter(item => item.key != 'operation')
      if (this.info.status == '数据已存储') {
        this.columns.push({
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          width: "20%",
          slots: {
            customRender: "operation"
          }
        })
      }
    },
    async getOption() {
      let res = await GetOption({ id: this.record.id });
      this.option = res.data;
    },
    rowClassName(record, index) {
      if (!record.standardName) {
        return "tableYeollowBg"
      } else {
        return (index % 2 === 1 ? 'table-striped' : null)
      }
    },
    changePage(pagination) {
      this.current = pagination.current
      this.pageSize = pagination.pageSize
    },
    async handleChange(type) {
      await Operation({ id: this.record.id, operation: type });
      this.detail()
      this.getOption()
    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async save(id) {
      let _data = this.data;
      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        delete this.editableData[id];
        return;
      }
      let medicineId = this.$store.state.medicineList.find(item => item.name == this.editableData[id].standardName).id
      let params = {
        id,
        medicineId,
        standardName: this.editableData[id].standardName
      }
      let res = await Update(params)

      if (res.errCode == 0) {
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        delete this.editableData[id];
      }

    },
    cancel(id) {
      delete this.editableData[id];
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      this.data = this.data.filter(function (item) {
        return item.id !== id;
      });
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    },
    async addToTable() {
      // let item = this.$store.state.prescriptionList.find(item => item.id == this.prescription)
      // this.data.items.push(item)
      // console.log(this.prescription);	
      if (this.data.find(item => item.prescriptionId == this.prescription)) {
        this.$message.error("该处方已存在")
        return
      }
      await Add({ prescriptionId: this.prescription, category: this.record.category, diseaseId: this.record.id })
      this.detail();
    },
  },
  watch: {
    record(val) {
      this.detail()
      this.getOption()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.tableStyle {
  border: 1px solid #f0f0f0;
  padding: 10px;
}
.thead > .tr {
  background-color: #fafafa;
}
.tr {
  display: flex !important;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
}
.tr > div {
  width: 12.5%;
  padding: 5px 12px;
}
.w100 {
  width: 100%;
}
.w100 .radioGroup label {
  width: auto;
}
.table-striped {
  background: #000;
}
</style>
